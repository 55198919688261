<template>
    <ContentField>
        <table class="table table-striped table-hover table-sm" style="text-align: center;">
            <thead>
                <tr>
                    <th>玩家</th>
                    <th>积分</th>
                </tr>   
            </thead>
            <tbody>
                <tr v-for="user in users" :key="user.id" class=align-middle>
                    <td>
                        <img :src="user.photo" alt="" class="user_photo">&nbsp;
                        <div class="username userA">{{user.username}}</div>
                    </td>
                
                    <td>
                        {{user.rating}}
                    </td>

                </tr>
            </tbody>
        </table>

        <nav aria-label="...">
        <ul class="pagination" style="float: right;">
            <li class="page-item" @click="click_page(-2)">
                <a class="page-link" href="#">&laquo;</a>
            </li>
            <li :class="'page-item ' + page.is_active" v-for="page in pages" :key="page.number" @click="click_page(page.number)">
                <a class="page-link" href="#">{{ page.number }}</a>
            </li>
            <li class="page-item" @click="click_page(-1)">
                <a class="page-link" href="#">&raquo;</a>
            </li>
        </ul>
        </nav>

    </ContentField>
</template>

<script>
import ContentField from '../../components/ContentField.vue';
import { useStore } from 'vuex';
import $ from 'jquery';
import { ref } from 'vue';

export default {
    components: {
        ContentField
    },
    setup() {
        const store = useStore();
        let currentPage = 1;
        let total_users = 0; // 保存总对局数
        let users = ref([]); // 保存对战记录
        let pages = ref([]); // 展示的多个对战页面

        const updatePages = () => {
            let max_pages = parseInt(Math.ceil(total_users / 8));
            let new_pages = [];
            for (let i = currentPage - 2; i <= currentPage + 2; i++) {
                if (i >= 1 && i <= max_pages) {
                    new_pages.push({
                        number: i,
                        is_active: i === currentPage ? "active" : "",
                    })
                }
            }
            pages.value = new_pages;
        }

        const click_page = page => {
            let max_pages = parseInt(Math.ceil(total_users / 8));
            if (page === -2) page = currentPage - 1;
            else if (page === -1) page = currentPage + 1;
            if (page >= 1 && page <= max_pages) {
                pull_page(page);
            }
        }



        const pull_page = page => {
            currentPage = page;
            $.ajax({
                url: "https://kob.bnblogs.cc/api/ranklist/getlist/",
                type: "GET",
                data: {
                    page
                },
                headers:{
                    "Authorization": "Bearer " + store.state.user.token, // 任何需要登录才能显示的都要加这个验证
                },
                success(resp){
                    users.value = resp.users;
                    total_users = resp.users_count;
                    updatePages();
                }
            })
        }

        pull_page(currentPage);

        return {
            click_page,
            users,
            pages,
        }
    }
}
</script>

<style scoped>
.user_photo{
    width: 4vh;
    border-radius: 50%;
}
.username {
    font-weight: bold;
    font-size: 1vw;
}

.userA {
   color: blue;
}

.userB {
    color: red;
}

</style>