<template>
    <div class="playground">
        <GameMap />
    </div>
</template>

<script>
import GameMap from './GameMap.vue' // 导入GameMap组件(.vue结尾)

export default {
    components: { // 使用GameMap组件
        GameMap,
    }
}
</script>

<style scoped> 
/* div标签的样式 */
div.playground {
    width: 60vw;
    height: 70vh;
    margin: 40px auto;
}
</style>