<template>
    <ContentField>
        <table class="table table-striped table-hover table-sm align-middle" style="text-align: center;">
            <thead>
                <tr>
                    <th>player A</th>
                    <th>player B</th>
                    <th>对战结果</th> 
                    <th>对战时间</th>
                    <th>操作</th>
                </tr>   
            </thead>
            <tbody>
                <tr v-for="record in records" :key="record.record.id" class=align-middle>
                    <td >
                        <img :src="record.a_photo" alt="" class="user_photo">&nbsp;
                        <div class="username userA">{{record.a_username}}</div>
                    </td>
                    <td>
                        <img :src="record.b_photo" alt="" class="user_photo">&nbsp;
                        <div class="username userB">{{record.b_username}}</div>
                    </td>
                    <td>
                        <div class="info">
                            {{record.result}}
                        </div>
                        
                    </td>

                    <td>
                        <div class="info">
                            {{record.record.createTime}}
                        </div>
                    </td>
                    <td>
                        <button class="btn btn-info sm" @click="open_record_content(record.record.id)">查看录像</button>
                    </td>
                </tr>
            </tbody>
        </table>

        <nav aria-label="..." style="float: right;">
            <ul class="pagination">
                <li class="page-item">
                <a class="page-link" href="#" @click="click_page(-2)">&laquo;</a>
                </li>
                <li :class="'page-item ' + page.is_active" v-for="page in pages" :key="page.number" @click="click_page(page.number)">
                    <a class="page-link" href="#">{{page.number}}</a>
                </li>
                <li class="page-item">
                <a class="page-link" href="#" @click="click_page(-1)">&raquo;</a>
                </li>
            </ul>
        </nav>
    </ContentField>
</template>

<script>
import ContentField from '../../components/ContentField.vue';
import router from '../../router/index'
import { useStore } from 'vuex';
import $ from 'jquery';
import { ref } from 'vue';

export default {
    components: {
        ContentField
    },
    setup() {
        const store = useStore();
        let currentPage = 1;
        let total_records = 0; // 保存总对局数
        let records = ref([]); // 保存对战记录
        let pages = ref([]); // 展示的多个对战页面

        const updatePages = () => {
            let max_pages = parseInt(Math.ceil(total_records / 8));
            let new_pages = [];
            for (let i = currentPage - 2; i <= currentPage + 2; i++) {
                if (i >= 1 && i <= max_pages) {
                    new_pages.push({
                        number: i,
                        is_active: i === currentPage ? "active" : "",
                    })
                }
            }
            pages.value = new_pages;
        }

        const click_page = page => {
            if (page === -2) page = currentPage - 1;
            else if (page === -1) page = currentPage + 1;
            let max_pages = parseInt(Math.ceil(total_records / 8));

            if (page >= 1 && page <= max_pages) {
                pull_page(page);
            }
        }



        const pull_page = page => {
            currentPage = page;
            $.ajax({
                url: "https://kob.bnblogs.cc/api/record/list/",
                type: "GET",
                data: {
                    page
                },
                headers:{
                    "Authorization": "Bearer " + store.state.user.token, // 任何需要登录才能显示的都要加这个验证
                },
                success(resp){
                    records.value = resp.records;
                    total_records = resp.records_count;
                    updatePages();
                }
            })
        }

        pull_page(currentPage);

        const stringTo2D = map => {
            let g = [];
            for (let i = 0, k = 0; i < 13; i++) {
                let line = [];
                for (let j = 0; j < 14; j++,k++) {
                    if(map[k] === '0') {
                        line.push(0);
                    }else {
                        line.push(1);
                    }
                }
                g.push(line);
            }
            return g;
        }


        // 打开对应recordId保存的录像 
        const open_record_content = (recordId) => {
            for (const record of records.value) {
                if (record.record.id === recordId) {
                    store.commit("updateIsRecord",true); // 播放录像
                    // 恢复游戏信息
                    store.commit("updateGame",{
                        map: stringTo2D(record.record.map),
                        a_id: record.record.aid,
                        a_sx: record.record.asx,
                        a_sy: record.record.asy,
                        b_id: record.record.bid,
                        b_sx: record.record.bsx,
                        b_sy: record.record.bsy,
                    });

                    // 恢复蛇的移动信息
                    store.commit("updateSteps",{
                        a_steps: record.record.asteps,
                        b_steps: record.record.bsteps,
                    })

                    // 恢复对局的败者
                    store.commit("updateRecordLoser",record.record.loser);

                    router.push({
                        name: "recordContent",
                        params:{
                            recordId,
                        }
                    });
                    break;
                }
            }

        }

        return {
            open_record_content,
            click_page,
            records,
            pages,
        }
    }
}
</script>

<style scoped>
.user_photo{
    width: 4vh;
    border-radius: 50%;
}
.username {
    font-weight: bold;
    font-size: 1vw;
}

.userA {
   color: blue;
}

.userB {
    color: red;
}

button {
    cursor: pointer;
}

button:hover {
    background-color: coral;
    border: none;
    border-color: coral;
}

</style>