<template>
    <div class="matchground">
        <div class="row">
            <div class="col-4">
                <div class="user_photo">
                    <img :src="$store.state.user.photo" alt="">
                </div>
                <div class="username">
                    {{ $store.state.user.username }}
                </div>                
            </div>
            <div class="col-4">
                <div class="select-bot">
                    <select class="form-select" v-model="select_bot" aria-label="Default select example">
                        <option value="-1">用户出战</option>
                        <option v-for="bot in bots" :key="bot.id" :value="bot.id">{{bot.title}}</option>
                        
                    </select>
                </div>
            </div>

            
            <div class="col-4">
                <div class="user_photo">
                    <img :src="$store.state.pk.opponent_photo" alt="">
                </div>
                <div class="username">
                    {{ $store.state.pk.opponent_username }}
                </div>   
            </div>

        <div style="text-align:center" class="load" v-if="$store.state.pk.status === 'matching' && !is_matched"  >
            <span style="font-size:1.2rem;font-weight:bold;color:white;">正在匹配中&nbsp;</span>
            <span class="spinner-border spinner-border-sm" style="color:white;" role="status" aria-hidden="true"></span>
        </div>

            <div class="col-12" style="text-align:center">
                    <button type="button" v-if="is_matched" class="btn btn-success btn-lg" @click="click_match_btn">{{match_btn_info}}</button>
                    <button type="button" v-else class="btn btn-danger btn-lg" @click="click_match_btn">{{match_btn_info}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import {useStore} from 'vuex';
import $ from 'jquery';

export default {
    setup() {
        const store = useStore();

        let match_btn_info = ref("开始匹配");
        let is_matched = ref(true);
        let bots = ref([]);
        let select_bot = ref("-1");

        const click_match_btn = ()  => {
            if (match_btn_info.value === "开始匹配") {
                match_btn_info.value = "取消匹配";
                is_matched.value = false;
                // 给后端发送一个json信息表示当前属于哪个事件
                store.state.pk.socket.send(JSON.stringify({
                    event: "start-matching",
                    bot_id: select_bot.value,
                }));

            }else {
                match_btn_info.value = "开始匹配";
                is_matched.value = true;
                store.state.pk.socket.send(JSON.stringify({
                    event: "stop-matching",
                }));
            }
        }

        const refresh_bots = () => {
            $.ajax({
                url: "https://kob.bnblogs.cc/api/user/bot/getlist/",
                type: "GET",
                headers:{
                    "Authorization": "Bearer " + store.state.user.token, // 任何需要登录才能显示的都要加这个验证
                },
                success(resp){
                    // 将后端得到的数据传给bot
                    bots.value = resp;
                }
            })
        }
        refresh_bots();
        return {
            match_btn_info,
            is_matched,
            click_match_btn,
            bots,
            select_bot,
        }
    },
}
</script>


<style scoped>
.matchground {
    width: 60vw;
    height: min(60vh,60vw);
    margin: 10vh auto;
    background-color: rgba(100, 116, 120, 0.5);

}

img {
    margin-top: 10vh;
    border-radius: 50%;
    width: min(20vh,15vw);
}

.user_photo {
    text-align: center;
}

.username {
    margin-top: 3vh;
    font-size: 3vh;
    font-weight: bold;
    color:black;
    text-align: center;
}

.select-bot {
    padding-top: 15vh;
}

.select-bot > select {
    font-size: 2.5vh;
    margin: 0 auto;

}

button {
    margin-top: 4vh;
    text-align: center;
    font-size: 3vh;
}


</style>