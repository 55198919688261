<template>
  <div class="container">
    <div class="row">
      <div class="col-3">
        <div class="card" style="margin-top: 30px">
          <div class="card-body">
            <img :src="$store.state.user.photo" alt="" />
          </div>
        </div>
      </div>

      <div class="col-9">
        <div class="card" style="margin-top: 30px">
          <div class="card-header">
            <span style="font-size: 20px; font-weight: bold">我的bots</span>
            <button
              type="button"
              class="btn btn-primary float-end"
              btn-sm
              data-bs-toggle="modal"
              data-bs-target="#add_bot_btn"
            >
              创建bot
            </button>
            <!-- Modal -->
            <div class="modal fade" id="add_bot_btn" tabindex="-1">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      创建新的bot
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <form>
                      <div class="mb-3">
                        <label for="add_bot_title" class="form-label"
                          >bot名称</label
                        >
                        <input
                          type="text"
                          v-model="new_bot.title"
                          class="form-control"
                          id="add_bot_title"
                          placeholder="请输入新bot的名称"
                        />
                      </div>
                      <div class="mb-3">
                        <label for="add_bot_description" class="form-label"
                          >bot简介</label
                        >
                        <textarea
                          class="form-control"
                          v-model="new_bot.description"
                          id="add_bot_description"
                          rows="3"
                          placeholder="请输入新bot的简介"
                        ></textarea>
                      </div>

                      <div class="mb-3">
                        <label for="add_bot_code" class="form-label"
                          >bot的代码</label
                        >
                        <VAceEditor
                          v-model:value="new_bot.content"
                          @init="editorInit"
                          lang="c_cpp"
                          theme="textmate"
                          :options="{ fontSize: 16 }"
                          style="height: 200px"
                        />
                      </div>
                    </form>
                  </div>
                  <div class="modal-footer">
                    <div class="error_msg">{{ new_bot.error_msg }}</div>
                    <button
                      type="button"
                      class="btn btn-success"
                      @click="add_bot"
                      btn-sm
                    >
                      创建
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger"
                      btn-sm
                      @click="cancel_add"
                    >
                      取消
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <table class="table table-striped table-hover">
              <!--表头-->
              <thead>
                <tr>
                  <th>名称</th>
                  <th>创建时间</th>
                  <th>操作</th>
                </tr>
              </thead>
              <!--内容-->
              <tbody>
                <tr v-for="bot in bots" :key="bot.id">
                  <td>{{ bot.title }}</td>
                  <td>{{ bot.createTime }}</td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-success"
                      btn-sm
                      style="margin-right: 10px"
                      data-bs-toggle="modal"
                      :data-bs-target="'#update-bot-btn-' + bot.id"
                    >
                      修改
                    </button>
                    <button
                      type="button"
                      class="btn btn-danger"
                      btn-sm
                      data-bs-toggle="modal"
                      :data-bs-target="'#remove-bot-btn-' + bot.id"
                    >
                      删除
                    </button>

                    <div
                      class="modal fade"
                      :id="'remove-bot-btn-' + bot.id"
                      tabindex="-1"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title">删除提示框</h5>
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div class="modal-body">
                            <p>是否确定要删除该bot</p>
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-info"
                              data-bs-dismiss="modal"
                            >
                              我再想想
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger"
                              @click="remove_bot(bot)"
                            >
                              确认删除
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="modal fade"
                      :id="'update-bot-btn-' + bot.id"
                      tabindex="-1"
                    >
                      <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title">修改bot信息</h5>
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div class="modal-body">
                            <form>
                              <div class="mb-3">
                                <label for="add_bot_title" class="form-label"
                                  >bot名称</label
                                >
                                <input
                                  type="text"
                                  v-model="bot.title"
                                  class="form-control"
                                  id="add_bot_title"
                                />
                              </div>
                              <div class="mb-3">
                                <label
                                  for="add_bot_description"
                                  class="form-label"
                                  >bot简介</label
                                >
                                <textarea
                                  class="form-control"
                                  v-model="bot.description"
                                  id="add_bot_description"
                                  rows="3"
                                ></textarea>
                              </div>

                              <div class="mb-3">
                                <label for="add_bot_code" class="form-label"
                                  >bot的代码</label
                                >
                                <VAceEditor
                                  v-model:value="bot.content"
                                  @init="editorInit"
                                  lang="c_cpp"
                                  theme="textmate"
                                  :options="{ fontSize: 16 }"
                                  style="height: 200px"
                                />
                              </div>
                            </form>
                          </div>
                          <div class="modal-footer">
                            <div class="error_msg">{{ bot.error_msg }}</div>
                            <button
                              type="button"
                              class="btn btn-success"
                              @click="update_bot(bot)"
                              btn-sm
                            >
                              保存修改
                            </button>
                            <button
                              type="button"
                              class="btn btn-danger"
                              btn-sm
                              @click="cancel_update(bot)"
                            >
                              取消
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import $ from "jquery";
import { useStore } from "vuex";
import { Modal } from "bootstrap/dist/js/bootstrap";

import { VAceEditor } from "vue3-ace-editor";
import ace from "ace-builds";

export default {
  components: {
    VAceEditor,
  },
  setup() {
    ace.config.set(
      "basePath",
      "https://cdn.jsdelivr.net/npm/ace-builds@" +
        require("ace-builds").version +
        "/src-noconflict/"
    );
    const store = useStore();
    let bots = ref([]); // 定义bot列表
    const new_bot = reactive({
      title: "",
      description: "",
      content: "",
      error_msg: "",
    });

    const cancel_update = (bot) => {
      Modal.getInstance("#update-bot-btn-" + bot.id).hide();
      refresh_bots();
    };

    const cancel_add = () => {
      Modal.getInstance("#add_bot_btn").hide();
      refresh_bots();
    };

    const refresh_bots = () => {
      $.ajax({
        url: "https://kob.bnblogs.cc/api/user/bot/getlist/",
        type: "GET",
        headers: {
          Authorization: "Bearer " + store.state.user.token, // 任何需要登录才能显示的都要加这个验证
        },
        success(resp) {
          // 将后端得到的数据传给bot
          bots.value = resp;
        },
      });
    };
    refresh_bots();

    // 点击创建bot按钮触发的事件
    const add_bot = () => {
      new_bot.error_msg = "";
      $.ajax({
        url: "https://kob.bnblogs.cc/api/user/bot/add/",
        type: "POST",
        data: {
          title: new_bot.title,
          description: new_bot.description,
          content: new_bot.content,
        },
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
          if (resp.error_msg === "success") {
            // 添加bot成功
            (new_bot.title = ""),
              (new_bot.description = ""),
              (new_bot.content = ""),
              Modal.getInstance("#add_bot_btn").hide();
            refresh_bots();
          } else {
            new_bot.error_msg = resp.error_msg; // 返回错误信息
          }
        },
      });
    };

    // 点击更新bot按钮触发的事件
    const update_bot = (bot) => {
      $.ajax({
        url: "https://kob.bnblogs.cc/api/user/bot/update/",
        type: "POST",
        data: {
          bot_id: bot.id,
          title: bot.title,
          description: bot.description,
          content: bot.content,
        },
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
          if (resp.error_msg === "success") {
            // 更新bot成功
            Modal.getInstance("#update-bot-btn-" + bot.id).hide();
            refresh_bots();
          } else {
            new_bot.error_msg = resp.error_msg; // 返回错误信息
          }
        },
      });
    };

    // 点击删除bot按钮触发的事件
    const remove_bot = (bot) => {
      $.ajax({
        url: "https://kob.bnblogs.cc/api/user/bot/remove/",
        type: "POST",
        data: {
          bot_id: bot.id,
        },
        headers: {
          Authorization: "Bearer " + store.state.user.token,
        },
        success(resp) {
          if (resp.error_msg === "success") {
            Modal.getInstance("#remove-bot-btn-" + bot.id).hide();
            // 删除bot成功
            refresh_bots();
          }
        },
      });
    };

    return {
      bots,
      new_bot,
      add_bot,
      remove_bot,
      update_bot,
      cancel_update,
      cancel_add,
    };
  },
};
</script>

<style scoped>
img {
  width: 100%;
}
.error_msg {
  font-size: 20px;
  font-weight: bold;
  color: red;
}
</style>