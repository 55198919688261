import { createRouter, createWebHistory } from 'vue-router'
import PkIndexView from '../views/pk/PkIndexView'
import RecordIndexView from '../views/record/RecordIndexView'
import RecordContentView from "../views/record/RecordContentView"
import RanklistIndexView from '../views/ranklist/RanklistIndexView'
import UserBotIndexView from '../views/user/bot/UserBotIndexView'
import NotFound from '../views/error/NotFound'
import UserLoginView from "@/views/user/account/UserLoginView"
import UserRegisterView from "@/views/user/account/UserRegisterView"
import UserAcWingCodeIndex from "@/views/user/account/UserAcWingCodeIndex"


import store from '@/store'

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/pk/",
    meta: {
      requestAuth: true,
    },
  },
  {
    path: "/pk/",
    name: "pk_index",
    component: PkIndexView,
    meta: {
      requestAuth: true,
    },
  },
  {
    path: "/record/",
    name: "record_index",
    component: RecordIndexView,
    meta: {
      requestAuth: true,
    },
  },
  {
    path: "/record:recordId/",
    name: "recordContent",
    component: RecordContentView,
    meta: {
      requestAuth: true,
    },
  },
  {
    path: "/ranklist/",
    name: "ranklist_index",
    component: RanklistIndexView,
    meta: {
      requestAuth: true,
    },
  },
  {
    path: "/user/bot/",
    name: "user_bot_index",
    component: UserBotIndexView,
    meta: {
      requestAuth: true,
    },
  },
  {
    path: "/404/",
    name: "404",
    component: NotFound,
    meta: {
      requestAuth: false,
    },
  },{
    path: "/user/account/login/",
    name: "login",
    component: UserLoginView,
    meta: {
      requestAuth: false,
    },
  },
  {
    path: "/user/account/register/",
    name: "register",
    component: UserRegisterView,
    meta: {
      requestAuth: false,
    },
  },{
    path: "/user/account/acwing/web/receive_code/",
    name: "Acwing",
    component: UserAcWingCodeIndex,
    meta: {
      requestAuth: false,
    },
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404/"
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to,from,next) => {
  if (to.meta.requestAuth && !store.state.user.is_login){
    next({name:"login"}); // 如果未登录且访问需要授权的页面，则先跳转到登陆页面
  }
  else {
    next(); // 跳转到pk(home)页面
  }
})

export default router
