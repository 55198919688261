export default {
    state: { // 全局变量
        status: "matching",  // matching表示匹配界面,playing表示对战界面
        socket: null,
        opponent_username: "", //对手的用户名
        opponent_photo: "", // 对手的头像
        gamemap: null, // 对战的地图
        a_id: 0,
        a_sx: 0,
        a_sy: 0,
        b_id: 0,
        b_sx: 0,
        b_sy: 0,
        gameObject: null,
        loser: "none", //"none","all","A","B" none表示还没有结果
        loading: true, // 显示正在加载中
    },
    getters: {
    },
    mutations: { // 用于修改全局数据
        updateSocket(state, socket) {
            state.socket = socket;
        },
        updateOpponent(state, opponent) {
            state.opponent_username = opponent.username;
            state.opponent_photo = opponent.photo;
        },
        updateStatus(state, status) {
            state.status = status;
        },
        updateLoading(state, loading) {
            state.loading = loading;
        },
        updateGame(state, game) {
            state.gamemap = game.map;
            state.a_id = game.a_id;
            state.a_sx = game.a_sx;
            state.a_sy = game.a_sy;
            state.b_id = game.b_id;
            state.b_sx = game.b_sx;
            state.b_sy = game.b_sy;

        },
        updateGameObject(state, gameObject) {
            state.gameObject = gameObject;
        },
        updateLoser(state, loser) {
            state.loser = loser;
        }
    },
    actions: { // 在actions中调用修改全局变量的函数

    },
    modules: {
    }
}