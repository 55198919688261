import { AcGameObject } from "./AcGameObject"; //  导入js的export class
import { Snake } from "./Snake";
import { Wall } from "./Wall";

export class GameMap extends AcGameObject {
    constructor(ctx, parent, store) {
        super(); // 继承类一直要先调用父类的构造函数

        this.ctx = ctx;
        this.parent = parent;
        this.L = 0;

        this.rows = 13;
        this.cols = 14;

        this.store = store;

        this.inner_walls_count = 20; // 障碍物的数量(最大建议80)
        this.walls = []; // 所有障碍物组成的数组

        // 创建两条蛇

        this.snakes = [ // 蓝蛇和红蛇
            new Snake({ id: 0, color: "#4876EC", r: this.rows - 2, c: 1 }, this),
            new Snake({ id: 1, color: "#F94848", r: 1, c: this.cols - 2 }, this),
        ];
    }

    create_walls() { // 判断是否生成有效的地图
        const g = this.store.state.pk.gamemap;
        for (let r = 0; r < this.rows; r++) {
            for (let c = 0; c < this.cols; c++) {
                if (g[r][c]) {
                    // 每次添加一个障碍物
                    this.walls.push(new Wall(r, c, this));
                }
            }
        }
    }

    add_listening_events() { // 绑定监听事件
        if (this.store.state.record.is_record === true) { // 播放录像
            let k = 0;
            const a_steps = this.store.state.record.a_steps;
            const b_steps = this.store.state.record.b_steps;
            const loser = this.store.state.record.record_loser;
            const [snake0,snake1] = this.snakes;

            let interval_id = setInterval(() => {
                if (k >= a_steps.length - 1) { // 不回放最后一步，最后一步直接展示结果
                    if (loser == "all" || loser == "A") {
                        snake0.status = "die";
                    }
                    if (loser == "all" || loser == "B") {
                        snake1.status = "die";
                    }
                    clearInterval(interval_id);
                }else {
                    snake0.set_direction(parseInt(a_steps[k]));
                    snake1.set_direction(parseInt(b_steps[k]));
                }
                k++;

            },300);
        }else {
            this.ctx.canvas.focus(); // 聚焦
            // 用wasd或者上右下左控制方向
            this.ctx.canvas.addEventListener("keydown", e => {
                let d = -1;
                if (e.key === 'w' || e.key === "ArrowUp") d = 0;
                else if (e.key === 'd'  || e.key === "ArrowRight") d = 1;
                else if (e.key === 's' || e.key === "ArrowDown")  d = 2;
                else if (e.key === 'a' || e.key === "ArrowLeft") d = 3;
                this.store.state.pk.socket.send(JSON.stringify({
                    event: "move",
                    direction: d,
                }));
            });
        }
       
    }



    start() {
        this.create_walls();
        this.add_listening_events();
    }

    update_size() { // 每过一帧重新生成新的地图尺寸
        // 取整数
        this.L = parseInt(Math.min(this.parent.clientWidth / this.cols, this.parent.clientHeight / this.rows));
        this.ctx.canvas.width = this.L * this.cols;
        this.ctx.canvas.height = this.L * this.rows;
    }

    update() { // 更新地图:每隔一帧都要重新渲染
        this.update_size(); // 更新地图大小
        if (this.check_ready()) {
            this.next_step(); // 进入下一回合
        }
        this.render(); // 重新渲染
    }

    check_ready() { // 判断两条蛇是否准备好进行下一步
        for (const snake of this.snakes) {
            if (snake.status !== "idle") return false;
            if (snake.direction === -1) return false;
        }
        return true;
    }

    next_step() { // 让两条蛇进入下一回合
        for (const snake of this.snakes) {
            snake.next_step();
        }
    }

    check_vaild(cell) { // 检测蛇的目标位置是否合法：是否撞到两条蛇的身体和障碍物
        for (const wall of this.walls) {
            if (wall.r === cell.r && wall.c === cell.c) { // 撞到墙了
                return false;
            }
        }

        for (const snake of this.snakes) {
            let k = snake.cells.length;
            // 特殊处理!!!
            if (!snake.check_tail_increasing()) { // 当蛇为会前进的时候，蛇尾不需要判断，因为蛇头可以占据蛇尾的位置
                k--;
            }
            for (let i = 0; i < k; i++) {  // 依次判断是否会撞到两条蛇的身体
                if (snake.cells[i].r === cell.r && snake.cells[i].c === cell.c) { // 撞到蛇的身体了
                    return false;
                }
            }
        }

        return true;
    }

    render() {
        const color_even = "#AAD751", color_odd = "#A2D149";
        for (let r = 0; r < this.rows; r++) {
            for (let c = 0; c < this.cols; c++) {
                if ((r + c) % 2 == 0) {
                    this.ctx.fillStyle = color_even;
                } else {
                    this.ctx.fillStyle = color_odd;
                }
                this.ctx.fillRect(c * this.L, r * this.L, this.L, this.L);
            }
        }
    }
}