<template>
    <div class="show">
        <div class="snakeInfo" style="background-color: #4876ec;" v-if="$store.state.user.id == $store.state.pk.a_id">蓝方</div>
        <div class="snakeInfo" style="background-color: #f94848;" v-if="$store.state.user.id == $store.state.pk.b_id">红方</div>
    </div>
</template>

<script>
</script>

<style scoped>
.snakeInfo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    top: 30vh;
    left: 8vw;
    text-align: center;
    line-height: 50px;
}
</style>