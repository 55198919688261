<template>
  <!-- status等于playing才进入对战界面 -->
  <PlayGround v-if="$store.state.pk.status === 'playing'" />
  <MatchGround v-if="$store.state.pk.status === 'matching'" />
  <ResultBoard  v-if="$store.state.pk.loser !== 'none'"/>
  <UserInfo v-if="$store.state.pk.status === 'playing'"/>
</template>

<script>
import PlayGround from "../../components/PlayGround.vue";
import MatchGround from "../../components/MatchGround.vue";
import ResultBoard from '@/components/ResultBoard.vue';
import UserInfo from '@/components/UserInfo.vue';
import { onMounted, onUnmounted } from "vue"; // 加载组件和卸载组件
import { useStore } from "vuex";


export default {
    components: {
        PlayGround,
        MatchGround,
        ResultBoard,
        UserInfo,
    },
    setup() {
        const store = useStore();
        const socket_url = `wss://kob.bnblogs.cc/websocket/${store.state.user.token}/`;

        store.commit("updateLoser","none");
        store.commit("updateIsRecord",false);

        let socket = null;
        // 当前组件被加载(点击pk页面)时执行该函数
        // onMounted和setup作用相似
    onMounted(() => {
        store.commit("updateOpponent", {
            username: "我的对手",
            photo:
                "https://cdn.acwing.com/media/article/image/2022/08/09/1_1db2488f17-anonymous.png",
            });
      // 建立一个socket链接
    socket = new WebSocket(socket_url);

    socket.onopen = () => {
        store.commit("updateSocket", socket);
    };

    // 接收到后端发来的信息
    socket.onmessage = (msg) => {
        const data = JSON.parse(msg.data);

        // 匹配成功
        if (data.event === "start-matching") {
            store.commit("updateOpponent", {
                username: data.opponent_username,
                photo: data.opponent_photo,
            });

        setTimeout(() => {
            store.commit("updateStatus", "playing");
        },0.2); // 0.2秒后更新地图

        // 更新游戏信息(包括地图、玩家坐标和id)
        store.commit("updateGame", data.game);
        
        } else if (data.event === "move") {
            const game = store.state.pk.gameObject;
            const [snake0, snake1] = game.snakes;
            snake0.set_direction(data.a_direction);
            snake1.set_direction(data.b_direction);
            
        } else if (data.event === "result") {
            const game = store.state.pk.gameObject;
            const [snake0, snake1] = game.snakes;
            if (data.loser == "all" || data.loser == "A") {
                snake0.status = "die";
            }
            if (data.loser == "all" || data.loser == "B") {
                snake1.status = "die";
            }
            store.commit("updateLoser",data.loser);
        }
      };

    socket.onclose = () => {
        store.commit("updateStatus", "matching");
    };
    });
    // 切换其他页面或者关闭pk页面时调用
    onUnmounted(() => {
        store.commit("updateLoading",true);
        socket.close();
    });
  },
};
</script>

<style scoped></style>
