<template>
    <div></div>
</template>

<script>
import $ from 'jquery';
import router from '@/router/index'
import { useRoute } from "vue-router"
import { useStore } from "vuex";

export default {
    setup() {
        const myRouter = useRoute();
        const store= useStore();
        $.ajax({
            url: "https://kob.bnblogs.cc/api/user/account/acwing/web/receive_code/",
            type: "GET",
            data: {
                code: myRouter.query.code,
                state: myRouter.query.state,
            },
            success: resp => {
                if (resp.result === "success") {
                    // 保存jwt_token
                    localStorage.setItem("jwt_token",resp.jwt_token);
                    store.commit("updateToken",resp.jwt_token);
                    router.push({ name:"home" }); // 验证成功跳转到首页
                    store.commit("updatePullingInfo",false);
                }else { // 用户拒绝授权，跳转到注册页面
                    router.push({ name:"register" }); 
                }
            }
        });
    }
}
</script>


<style scoped>

</style>