<template>
  <div class="window">
    <NavBar /> <!--在前端页面中展示NavBar组件---->
    <router-view></router-view> 
  </div>

</template>

<script>
import NavBar from './components/NavBar.vue' // 导入NavBar组件(.vue)结尾
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap"

export default {
  components: { // 使用NavBar组件
    NavBar
  },
  setup() {
  }
}
</script>

<style>
body {
  background: url("@/assets/images/background.png") no-repeat center fixed;
  background-size: cover;
}

.window {
  width: 100%;
  height: 100%;
}
</style>
