<template>
    <div class="result-board">
        <div class="result-text" v-if="$store.state.pk.loser === 'all'">
            Draw
        </div>

        <div class="result-text" v-else-if="$store.state.pk.loser === 'A' && $store.state.pk.a_id === parseInt($store.state.user.id)">
            Lose
        </div>

        <div class="result-text" v-else-if="$store.state.pk.loser === 'B' && $store.state.pk.b_id == parseInt($store.state.user.id)">
            Lose
        </div>

        <div class="result-text" v-else>
            You Win
        </div>

        <div class="reset-btn">
            <button @click="reset" class="btn btn-warning lg">再来一次!</button>
        </div>
    </div>
</template>

<script>

import { useStore } from 'vuex';

export default {
    setup() {
        const store = useStore();

        const reset = () => {
            store.commit("updateLoading",true);
            store.commit("updateStatus","matching");
            store.commit("updateLoser","none");
            store.commit("updateOpponent", {
            username: "我的对手",
            photo:
                "https://cdn.acwing.com/media/article/image/2022/08/09/1_1db2488f17-anonymous.png",
            });
        }

        return {
            reset,
        }
    }

}
</script>


<style scoped> 
.result-board {
    width: 30vw;
    height: 30vh;
    background-color: rgba(34, 20, 77, 0.5);
    position: absolute;
    top: 30vh;
    left: 35vw;
}

.result-text {
    font-size: 50px;
    color:yellowgreen;
    font-weight: bold;
    text-align: center;
    font-style: italic;
    padding-top: 4vh;
    padding-bottom: 4vh;
}

.reset-btn {
    width: 100%;
    display: flex;
    justify-content: center;

}
</style>