const AC_GAME_OBJECTS = [];

export class AcGameObject {
    constructor() {
        AC_GAME_OBJECTS.push(this); // 数组添加元素
        this.timedelta = 0; // 注意单位是毫秒，转化为秒需要除于1000
        this.has_called_start = false;
    }

    start() {  // 只执行一次
    }

    update() {  // 每一帧执行一次，除了第一帧之外

    }

    on_destroy() {  // 删除之前执行

    }

    destroy() {
        this.on_destroy();

        for (let i in AC_GAME_OBJECTS) { // 遍历数组下标
            const obj = AC_GAME_OBJECTS[i];
            if (obj === this) { // 注意是三个等号
                AC_GAME_OBJECTS.splice(i); // 从数组中移除元素
                break;
            }
        }
    }
}

let last_timestamp;  // 上一次执行的时刻
const step = timestamp => {
    for (let obj of AC_GAME_OBJECTS) { // 遍历数组的值
        if (!obj.has_called_start) { // 如果对象没有被调用
            obj.has_called_start = true;
            obj.start();
        } else {
            obj.timedelta = timestamp - last_timestamp; // 获得当前帧和上一帧的时间差
            obj.update();
        }
    }

    last_timestamp = timestamp; // 将当前时间更新为上一帧执行时刻
    requestAnimationFrame(step)  // 递归调用该函数
}

requestAnimationFrame(step)