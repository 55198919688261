import $ from 'jquery'

export default {
    state: { // 全局变量
        id: "",
        username: "",
        photo: "",
        token: "",
        is_login: false, // 默认未登录状态
        pulling_info: true, // 是否正在拉取信息
    },
    getters: {
    },
    mutations: { // 用于修改全局数据
        updateUser(state,user) { // 更新用户信息
            state.id = user.id;
            state.username = user.username;
            state.photo = user.photo;
            state.is_login = user.is_login;
        },
        updateToken(state,token) { // 更新token
            state.token = token;
        },
        logout(state) {
            state.id = "";
            state.username = "";
            state.photo = "";
            state.token = "";
            state.is_login = false;
        },
        updatePullingInfo(state,pulling_info) {
            state.pulling_info = pulling_info;
        }
    },
    actions: { // 在actions中调用修改全局变量的函数
        login(context,data) {
            $.ajax({
                url: "https://kob.bnblogs.cc/api/user/account/token/",
                type : "POST",
                data: {
                  username: data.username,
                  password: data.password,
                },
                success(resp) { 
                    // resp对应的就是后端的map的json格式，每个字段对应键值对中的key
                    // 登陆成功
                    if(resp.error_msg === "success"){
                        // 调用updateToken函数，形参中的data为resp.token
                        // 将登陆信息的token存入localStorage实现持久化(浏览器刷新退出登陆)
                        localStorage.setItem("jwt_token",resp.token);
                        context.commit("updateToken",resp.token);
                        data.success(resp);
                    }else {
                        data.error(resp);
                    }
                },
                error(resp) {
                    data.error(resp);
                },
              });
        },
        getInfo(context,data) {
            $.ajax({
                url: "https://kob.bnblogs.cc/api/user/account/info/",
                type: "GET",
                headers: {
                  Authorization: "Bearer " + context.state.token,
                },
                success(resp) {  
                    if (resp.error_msg === "success"){
                        // 登陆成功后,更新当前用户信息
                        context.commit("updateUser",{
                            ...resp,
                            is_login: true,
                        });
                        data.success(resp); // 调用UserLoginView中的success()回调函数
                    }else {
                        data.error(resp); // 调用UserLoginView中的error()回调函数
                    }
                },
                error(resp) {
                  data.error(resp);
                },
              });
        },
        logout(context) {
            // 退出时删除localStorage保存的token
            localStorage.removeItem("jwt_token");
            context.commit("logout"); // 退出登录
        }
    },
    modules: {
    }
}