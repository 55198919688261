<template>
  <ContentField>
    <div class="row justify-content-md-center">
      <div class="col-3">
        <form @submit.prevent="register">
          <div class="mb-3">
            <label for="username" class="form-label">用户名</label>
            <input
              v-model="username"
              type="text"
              class="form-control"
              id="username"
              placeholder="请输入用户名"
            />
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">密码</label>
            <input
              v-model="password"
              type="password"
              class="form-control"
              id="password"
              placeholder="请输入密码"
            />
          </div>

          <div class="mb-3">
            <label for="confirm_password" class="form-label">确认密码</label>
            <input
              v-model="confirm_password"
              type="password"
              class="form-control"
              id="confirm_password"
              placeholder="请再输入一次密码"
            />
          </div>
          <div class="error_msg">{{ error_msg }}</div>
          <button type="submit" class="btn btn-primary btn-sm">注册</button>
        </form>
      </div>
    </div>
  </ContentField>
</template>

<script>
import ContentField from "@/components/ContentField.vue";
import { ref } from "vue";
import router from "@/router/index";
import $ from 'jquery';

export default {
  components: {
    ContentField,
  },
  setup() {
    let username = ref("");
    let password = ref("");
    let confirm_password = ref("");
    let error_msg = ref("");
    const register = () => {
      $.ajax({
        url: "https://kob.bnblogs.cc/api/user/account/register/",
        type: "POST",
        data: {
          username: username.value,
          password: password.value,
          confirmedPassword: confirm_password.value,
        },
        success(resp) {
          // 注册成功，跳转到登陆界面
          if (resp.error_msg === "success") {
            router.push({ name: "login" });
          } else {
            error_msg.value = resp.error_msg;
          }
        },
      });
    };
    return {
      username,
      password,
      confirm_password,
      error_msg,
      register,
    };
  },
};
</script>

<style scoped>
button {
  width: 100%;
}
.error_msg {
  margin-bottom: 10px;
  color: red;
  font-weight: bold;
}
</style>
