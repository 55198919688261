<template>
  <ContentField v-if="!$store.state.user.pulling_info">
  <div class="row justify-content-md-center">
    <div class="col-3">
        <form @submit.prevent="login">
          <div class="mb-3">
            <label for="username" class="form-label">用户名</label>
            <!--使用v-model绑定下面的变量,input中的值传递给username变量-->
            <input
              v-model="username" 
              type="text"
              class="form-control"
              id="username"
              placeholder="请输入用户名"
            />
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">密码</label>
            <input v-model="password"
            type="password" class="form-control" id="password" placeholder="请输入密码"/>
          </div>
          <div class="error-messsage"> {{error_msg}} </div>
          <button type="submit" class="btn btn-primary btn-sm">登录</button>
        </form>
        <div style="text-align: center;margin-top: 14px;cursor: pointer" @click="acwing_login">
          <img width="40" src="https://cdn.acwing.com/media/article/image/2022/09/06/1_32f001fd2d-acwing_logo.png" alt="">
          <div>AcWing一键登录</div>
        </div>
    </div>
  </div>
  </ContentField>
</template>

<script>
import ContentField from "@/components/ContentField.vue";
import { useStore } from "vuex";
import { ref } from "vue";
import router from '@/router/index';
import $ from 'jquery';

export default {
  components: {
    ContentField,
  },
  setup() {
    const store = useStore();
    let username = ref('');
    let password = ref('');
    let error_msg = ref('');

    // 如果localStorage中保存了token，那就读取出来并和后端信息比对，比对成功进入首页
    const jwt_token = localStorage.getItem("jwt_token");
    if (jwt_token) {
      store.commit("updateToken",jwt_token);
      store.dispatch("getInfo",{ // 向后端请求信息
        success(){
          router.push({name:"home"}); // 验证成功跳转到首页
          store.commit("updatePullingInfo",false);
        },
        error() {
          store.commit("updatePullingInfo",false);
        }
      })
    }
    else {
        store.commit("updatePullingInfo",false);
    }

    // 定义触发函数： 提交表单时触发login函数
    const login = () => {
      error_msg.value = "";
      // error_msg.value = ""; // 清空上一次的错误信息
      // 利用store调用actions中的函数,第一个参数时调用的函数名，第二个参数需要传入一些数据和一些回调函数
      store.dispatch("login",{
        username: username.value,
        password:password.value,
        success(){ // 登陆成功直接跳转到主页面
          store.dispatch("getInfo",{
            success() {
              router.push({name:"home"});
            }
          });
        },
        error() { // 登陆失败
          error_msg.value = "用户名或密码错误!"; 
        }
      });
    }

    const acwing_login = () => {
        $.ajax({
            url: "https://kob.bnblogs.cc/api/user/account/acwing/web/apply_code/",
            type: "GET",
            success: resp => {
                if (resp.result === "success") {
                    window.location.replace(resp.apply_code_url);
                }
            }
        });
    }

    return {
      username,
      password,
      error_msg,
      login,
      acwing_login,
    }
  }
};
</script>

<style scoped>
button {
  width: 100%;
}
.error-messsage{
    margin-bottom: 10px;
    color: red;
    font-weight: bold;
}
</style>
